import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Flex component wrapper
const Flex = (props) => {
  const theme = useTheme(); // Access the theme
  const { direction } = theme; // Get the direction from the theme

  return (
    <Box
      {...props} // Pass all props to the Box component
      display="flex"
      flexDirection={direction === 'rtl' ? 'row' : 'row'}
    >{props.children}</Box>
  );
};

export default Flex;
