// App.js
import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useTranslation } from 'react-i18next';
import routes from './routes';
import { Box, CircularProgress } from '@mui/material';
import useConfig from './hooks/useConfig';
import Loader from './components/common/Loader';
import { SocketProvider } from './context/SocketContext';

function App() {
  const { i18n } = useTranslation();
  const routing = useRoutes(routes);
  const { config, loading, error } = useConfig();

  // if (loading) {
  //   return <Loader />; 
  // }



  const currentLanguage = i18n.language;
  const direction = currentLanguage === 'ar' ? 'rtl' : 'ltr';

  // Set document direction
  document.body.dir = direction;

  // Create MUI theme with direction
  const theme = createTheme({
    direction: direction,
    palette: {
      primary: {
        main: '#EB6953', // Default primary color
      },
      background: {
        primaryOpacity: '#EB695330', // Primary color with opacity
        main: '#F3F3F1',
        white: '#FFFFFF',
        form: '#F8F8F7'
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SocketProvider>
        <Suspense fallback={
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
          </Box>
        }>
          {routing}
        </Suspense>
      </SocketProvider>
    </ThemeProvider>
  );
}

export default App;
