import { createSlice } from '@reduxjs/toolkit';
import { getAccessToken, getAuthUser, getRefreshToken } from '../utils/auth';

const initialState = {
    accessToken:getAccessToken(),
    refreshToken:getRefreshToken(),
    user:getAuthUser(),
    error:"",
    loginDetails:{}
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.refreshToken=action.payload.refreshToken;
      
    },
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken=null;
    },
    setLoginDetiles:(state,action)=>{
        state.loginDetails=action.payload
    }
  },
});

export const { loginSuccess, logout ,setLoginDetiles } = authSlice.actions;
export default authSlice.reducer;
