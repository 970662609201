import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Tooltip,
  Avatar,
  MenuItem,
  Divider,
  ListItemIcon,
  Menu,
} from "@mui/material";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function HeaderAppBar() {
  const localUserData = localStorage?.getItem("user");
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState();
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
  };

  const handleLogout = () => {
    logout();
    handleClose(); // Close the menu after logout
    navigate("/login");
  };

  useEffect(() => {
    if (localUserData) {
      try {
        let userData = JSON.parse(localUserData);
        setUser(userData);
      } catch (error) {
        console.error("Failed to parse localUserData:", error);
      }
    }
  }, [localUserData]);

  const closeAndNavigate = (path) => {
    setOpen(false);
    handleClose();
    navigate(path);
  };

  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        {/* Language Switch Button on the Left */}
        <Button color="inherit" onClick={toggleLanguage}>
          {i18n.language === "en" ? "العربية" : "English"}
        </Button>

        {/* Center Icon */}
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
          <img src="/logo.png" alt="Logo" style={{ height: "40px" }} />
        </Box>

        {/* Profile Icon on the Right */}
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              src={user?.profilePicture || ""}
              sx={{ width: 32, height: 32 }}
            />
          </IconButton>
        </Tooltip>

        {anchorEl ? (
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {isAuthenticated ? (
              <div>
                <MenuItem onClick={() => closeAndNavigate("/account")}>
                  <Avatar /> {t("PROFILE")}
                </MenuItem>
                <MenuItem onClick={() => closeAndNavigate("/account/profile")}>
                  <Avatar /> {t("MY_ACCOUNT")}
                </MenuItem>
                <Divider sx={{widht:'100%'}} />
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  {t("ADD_ANOTHER_ACCOUNT")}
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  {t("SETTINGS")}
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {t("LOGOUT")}
                </MenuItem>
              </div>
            ) : (
              <div>
                <MenuItem onClick={() => closeAndNavigate("/login")}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {t("LOGIN")}
                </MenuItem>
              </div>
            )}
          </Menu>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}

export default HeaderAppBar;
