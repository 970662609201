import api from "../utils/API";

// Base URL for your API
export const authService = {
  signUp: async (payload) => {
    try {
      const response = await api.post('/auth/register', payload);
      return response.data
    } catch (error) {
      throw error.response.data;
    }
  },

  verifyOTP: async (payload) => {
    try {
      const response = await api.post('/auth/verify-otp', payload);
      return response.data  
    } catch (error) {
    throw error.response.data;
    }
  },

  login: async (payload) => {
    try {
      const response = await api.post('/auth/login', payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  login: async (payload) => {
    try {
      const response = await api.post('/auth/login', payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  resetPassword: async (payload) => {
    try {
      const response = await api.post("/auth/reset-password", payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  forgotPassword: async (payload) => {
    try {
      const response = await api.post("/auth/forgot-password", payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
