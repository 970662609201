import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { Menu as MenuIcon, Search as SearchIcon } from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";
import { useTheme } from "@mui/system";
import Flex from "./Flex";
import { useTranslation } from "react-i18next";
import useCategory from "../../hooks/useCategory";
import useConfig from "../../hooks/useConfig";
import { Link, useNavigate } from "react-router-dom";
import getLocalizedValue from "../../utils/getLocalizedValue";

// Styled search bar
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

// Adjust search icon position based on theme direction
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  left: theme.direction === "rtl" ? 0 : "auto",
  right: theme.direction === "rtl" ? "auto" : 0,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft:
      theme.direction === "rtl" ? theme.spacing(0) : theme.spacing(4),
    paddingRight:
      theme.direction === "rtl" ? theme.spacing(4) : theme.spacing(0),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Navbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t } = useTranslation(); // The 't' function is used to translate keys
  const { categories } = useCategory();
  const { fetchConfig } = useConfig();

  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchValue);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    if (debouncedValue) {
      navigate(`/list?search=${encodeURIComponent(debouncedValue)}`);
    }
  }, [debouncedValue]);

  const handleSearchChange = (e) => {
    if (e.target.value.trim() === "") {
      navigate(`/list`);
    }
    setSearchValue(e.target.value.trim());
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCategoryClick = (id) => {
    navigate(`/list/${id}`);
    handleClose();
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  const navItems = [
    {
      label: t("HOME"),
      path: "/",
    },
    {
      label: t("AD_POST"),
      path: "/create",
    },
  ];

  // Drawer for mobile navigation
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {t("MENU")}
      </Typography>
      <List>
        {navItems.map((item) => (
          <ListItem
            component="a"
            href={item.path}
            sx={{ textAlign: theme.direction === "ltr" ? "center" : "start" }}
            key={item}
          >
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          color="white"
        >
          {t("CATEGORIES")}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleCategoryClick("")}>All</MenuItem>
          {categories.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => handleCategoryClick(item._id)}
              >
                {getLocalizedValue(item.name)}
              </MenuItem>
            );
          })}
        </Menu>
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        color="primary"
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <Toolbar>
          {/* Hamburger menu icon for mobile */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          {/* Desktop Nav Links */}
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Flex>
              {navItems.map((item, index) => (
                <Button variant="text" key={index}>
                  <Typography
                    key={item.label}
                    variant="body1"
                    component="a"
                    href={item.path}
                    sx={{
                      color: "white",
                      marginRight: theme.spacing(4),
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {item.label}
                  </Typography>
                </Button>
              ))}
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                color="white"
              >
                {t("CATEGORIES")}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleCategoryClick("")}>All</MenuItem>
                {categories.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => handleCategoryClick(item._id)}
                    >
                      {getLocalizedValue(item.name)}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Flex>

            {/* Search bar */}
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={t("SEARCH")}
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearchChange}
              />
            </Search>
          </Box>

          {/* Mobile Search Bar (displayed on small screens) */}
          <Search sx={{ display: { xs: "flex", sm: "none" } }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor={theme.direction === "ltr" ? "left" : "right"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Navbar;
