import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const settingsSlice = createSlice({
  name: "countries",
  initialState: {
    countryList: [],
    loading: false,
    error: null,
  },
  reducers: {
    setCountries: (state, action) => {
      state.items = action.payload.data.ads || [];
    },
  },
});

export const selectCountries = (state) => state?.countries?.items;

export const selectCountryLoading = (state) => state?.countries?.loading;
export const selectCountryError = (state) => state?.countries?.error;

export const {
    setCountries,
} = settingsSlice.actions;
export default settingsSlice.reducer;
