import api from "../utils/API";

export const configService = {

    fetchBanners: async () => {
        const response = await api.get('/banners');
        return response.data;
    },
    fetchCountry: async () => {
        const response = await api.get('/country');
        return response.data;
    },
    fetchConfig: async (type) => {
        let response
        if(type){
            response = await api.get(`/config`,{params:{type}});
        }else{
            response = await api.get(`/config`);
        }
        return response.data;
    },
    fetchFaq: async () => {
        const response = await api.get('/faq');
        return response.data;
    },
};
