import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const categorySlice = createSlice({
  name: "category",
  initialState: {
    items: [],
    specifications: [],
    additionalDetails: [],
    loading: false,
    error: null,
  },
  reducers: {
    setCategories: (state, action) => {
      state.items = action.payload.data.categories || [];
      state.loading = false;
    },
    setSpecifications: (state, action) => {
      state.specifications = action.payload.data || [];
      state.loading = false;
    },
    setadditionalDetails: (state, action) => {
      state.additionalDetails = action.payload.data || [];
      state.loading = false;
    },
  },
});

// Export the selector to access category data
export const selectCategories = (state) => state.category.items;

// Export the loading and error selectors if needed
export const selectCategoryLoading = (state) => state.category.loading;
export const selectCategoryError = (state) => state.category.error;
export const selectSpecifications = (state) => state.category.specifications;
export const selectadditionalDetails = (state) =>
  state.category.additionalDetails;

// Export the reducer to include in the store
export const { setCategories, setSpecifications, setadditionalDetails } =
  categorySlice.actions;
export default categorySlice.reducer;
