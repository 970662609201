import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    stepData: {
        adInfo: {
            breadcrumbs: [],
            selectedCategory: {
                categoryId: "",
                name: { en: "", ar: "" }
            },
            selectedCountry: '',
            title: '',
            price: '',
            description: '',
            location: {
                locationName: "",
                address: {},
                markerPosition: {
                    lat: -3.745,
                    lng: -38.523
                }
            },
        },
        images: {
            images: [],
            imageUrls:[],
        },
        form: {
            specification: [],
            optionalSpecifications: [],
            additionalDetails: []
        },
        formValue: {
            specification: {},
            optionalSpecifications: {},
            additionalDetails: {}
        }
    },
    currentStep: 0,
};

const createPostSlice = createSlice({
    name: 'createPost',
    initialState,
    reducers: {
        saveStepData: (state, action) => {
            const { step, data } = action.payload;
            state.stepData[step] = data;
        },
        saveAllStepData: (state, action) => {
            state.stepData = {...state.stepData,...action.payload};
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        resetPost: () => initialState,
    },
});

export const { saveStepData, setCurrentStep, resetPost,saveAllStepData } = createPostSlice.actions;

export default createPostSlice.reducer;
