import i18n from 'i18next';

/**
 * Helper function to manage dynamic localization based on i18n language
 * @param {object} data - The object containing data for both en and ar (e.g., { en: 'value', ar: 'value' })
 * @returns {string} - Returns the value based on the current language (en or ar)
 */
const getLocalizedValue = (data) => {
    const currentLanguage = i18n.language || 'en';
    if(!data){
        return ""
    }
    if (currentLanguage === 'ar' && data.ar) {
        return data.ar;
    }

    return data.en || '';
};

export default getLocalizedValue;
