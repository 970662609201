import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import HeaderAppBar from "./AppBar";
import Navbar from "./Navbar";

const ChatPageLayoutWrapper = () => {
  return (
    <>
      <HeaderAppBar />
      <Navbar />
      <Box component="main" bgcolor="background.main">
        <Outlet />
      </Box>
    </>
  );
};



export default ChatPageLayoutWrapper