import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    loading: false,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.data || {};
      state.loading = false;
    },
  },
});

// Export the selector to access user data
export const selectUser = (state) => state?.user?.user;

// Export the loading and error selectors if needed
export const selectUserLoading = (state) => state?.user?.loading;
export const selectUserError = (state) => state?.user?.error;

// Export the reducer to include in the store
export const { setUser } = userSlice.actions;
export default userSlice.reducer;
