import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n'; 
import { store } from './store/store'; 
import { Provider } from 'react-redux';
import { BrowserRouter  as Router } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Provider store={store}>
    <Router basename="/">
    <App />
    </Router>
  </Provider>
  </React.StrictMode>
);

