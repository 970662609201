import { createSlice } from "@reduxjs/toolkit";

// Create a slice for configuration, including banners
const configSlice = createSlice({
  name: "config",
  initialState: {
    data: {
      primaryColor: "",
      logo: "",
      contactUs: "",
      banners: [],
      countries: [],
      faqs: [],
      termsAndConditions: { en: "", ar: "" },
      privacyPolicy: { en: "", ar: "" },
      aboutUs: { en: "", ar: "" },
      footerText: { en: "", ar: "" },
      contactEmail: "",
      enableChatInContactUs: false,
      enableEmailInContactUs: false,
      enablePhoneInContactUs: false,
      enableWhatsAppInContactUs: false,
      contactPhone: "",
      privacyPolicy: { en: "", ar: "" },
      socialMediaAccounts: [],
      termsAndConditions: { en: "", ar: "" },
      contactWhatsAppNumber: "",
      footerLogo: "",
      headerLogo: "",
    },
    loading: false,
    error: null,
  },
  reducers: {
    setConfigLoading: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    setConfig: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.aboutUs = action.payload.aboutUsText;
      state.data.footerText = action.payload.footerText;
      state.data.contactEmail = action.payload.emial;
      state.data.enableChatInContactUs = action.payload.enableChatInContactUs;
      state.data.enableEmailInContactUs = action.payload.enableEmailInContactUs;
      state.data.enablePhoneInContactUs = action.payload.enablePhoneInContactUs;
      state.data.enableWhatsAppInContactUs =
        action.payload.enableWhatsAppInContactUs;
      state.data.contactPhone = action.payload.phone;
      state.data.privacyPolicy = action.payload.privacyPolicyText;
      state.data.socialMediaAccounts = action.payload.socialMediaAccounts;
      state.data.termsAndConditions = action.payload.termsAndConditionsText;
      state.data.contactWhatsAppNumber = action.payload.whatsAppNumber;
      state.data.footerLogo = action.payload.footerLogo;
      state.data.headerLogo = action.payload.headerLogo;
    },
    setConfigError: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    setBanners: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.banners = action.payload.data.banners;
    },
    setCountries: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.countries = action.payload.data.countries;
    },
    setAboutUs: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.aboutUs = action.payload;
    },
    setFaq: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.faqs = action.payload;
    },
    setTermsAndConditions: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.termsAndConditions = action.payload;
    },
    setPrivacyPolicy: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.privacyPolicy = action.payload;
    },
  },
});

// Export selectors to access configuration data
export const selectConfig = (state) => state.config.data;
export const selectBanners = (state) => state.config.data.banners;
export const selectCountries = (state) => state.config.data.countries;
export const selectAboutUs = (state) => state.config.data.aboutUs;
export const selectFaq = (state) => state.config.data.faqs;
export const selectTermsAndConditions = (state) =>
  state.config.data.termsAndConditions;
export const selectPrivacyPolicy = (state) => state.config.data.privacyPolicy;
export const selectLoading = (state) => state.config.loading;
export const selectError = (state) => state.config.error;

export const {
  setConfig,
  setConfigError,
  setConfigLoading,
  setBanners,
  setCountries,
  setAboutUs,
  setFaq,
  setTermsAndConditions,
  setPrivacyPolicy,
} = configSlice.actions;
export default configSlice.reducer;
