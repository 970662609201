import api from "../utils/API";

// Base URL for your API
export const categoryService = {
  fetchCategories: async () => {
    const response = await api.get("/category");
    return response.data;
  },
  fetchCategoryDetails: async (id) => {
    const response = await api.get(`/category/${id}`);
    return response.data;
  },
  fetchSpecificationsList: async (id) => {
    const response = await api.get("/specification");
    return response.data;
  },
  fetchAdditionalDetails: async (id) => {
    const response = await api.get("/additional-details");
    return response.data;
  },
};
