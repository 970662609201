import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectConfig,
  selectLoading,
  selectError,
  selectBanners,
  setConfig,
  setConfigLoading,
  setConfigError,
  setBanners,
  selectCountries,
  setCountries,
  setAboutUs,
  selectAboutUs,
  setFaq,
  setPrivacyPolicy,
  setTermsAndConditions,
  selectFaq,
  selectTermsAndConditions,
  selectPrivacyPolicy,
} from "../store/configSlice";
import { configService } from "../services/config.api";

const useConfig = () => {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const banners = useSelector(selectBanners);
  const countries = useSelector(selectCountries);
  const aboutUs = useSelector(selectAboutUs);
  const faqs = useSelector(selectFaq);
  const termsAndConditions = useSelector(selectTermsAndConditions);
  const privacyPolicy = useSelector(selectPrivacyPolicy);

  const fetchConfig = async () => {
    dispatch(setConfigLoading());
    const data = await configService.fetchConfig();
    if (data.success) {
      dispatch(setConfig(data.data));
    } else {
      dispatch(setConfigError());
    }
  };

  const fetchBanners = async () => {
    dispatch(setConfigLoading());
    const data = await configService.fetchBanners();
    if (data.success) {
      dispatch(setBanners(data));
    } else {
      dispatch(setConfigError());
    }
  };

  const fetchCountry = async () => {
    dispatch(setConfigLoading());
    const data = await configService.fetchCountry();
    if (data.success) {
      dispatch(setCountries(data));
    } else {
      dispatch(setConfigError());
    }
  };

  const fetchAboutUs = async () => {
    dispatch(setConfigLoading());
    const data = await configService.fetchConfig("about-us");
    if (data.success) {
      dispatch(setAboutUs(data.data.aboutUsText));
    } else {
      dispatch(setConfigError());
    }
  };

  const fetchFaq = async () => {
    dispatch(setConfigLoading());
    const data = await configService.fetchFaq();
    if (data.success) {
      dispatch(setFaq(data.data));
    } else {
      dispatch(setConfigError());
    }
  };

  const fetchTermsAndConditions = async () => {
    dispatch(setConfigLoading());
    const data = await configService.fetchConfig("terms-and-conditions");
    if (data.success) {
      dispatch(setTermsAndConditions(data.data.termsAndConditionsText));
    } else {
      dispatch(setConfigError());
    }
  };

  const fetchPrivacyPolicy = async () => {
    dispatch(setConfigLoading());
    const data = await configService.fetchConfig("privacy-policy");
    if (data.success) {
      dispatch(setPrivacyPolicy(data.data.privacyPolicyText));
    } else {
      dispatch(setConfigError());
    }
  };

  // useEffect(() => {
  //     fetchConfig()
  // }, []);

  return {
    config,
    banners,
    countries,
    aboutUs,
    loading,
    error,
    fetchBanners,
    fetchCountry,
    fetchAboutUs,
    fetchConfig,
    fetchFaq,
    fetchTermsAndConditions,
    fetchPrivacyPolicy,
    faqs,
    termsAndConditions,
    privacyPolicy,
  };
};

export default useConfig;
